import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Representative Comments`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For a given comment `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.43056em",
                    "verticalAlign": "0em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span></span></span></span>{`, and subset (group) in the conversation `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.19444em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`g`}</span></span></span></span>{`, the `}<a parentName="p" {...{
            "href": "/Representativeness",
            "title": "Representativeness"
          }}>{`Representativeness`}</a>{` of that comment `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.43056em",
                    "verticalAlign": "0em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span></span></span></span>{` for group `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.19444em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`g`}</span></span></span></span>{` (denoted `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1em",
                    "verticalAlign": "-0.25em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.00773em"
                    }
                  }}>{`R`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.151392em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.5500000000000003em",
                              "marginLeft": "-0.00773em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`v`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mopen"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`g`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.16666666666666666em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span></span></span></span>{`), is a measure of how much more likely participants in group `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.19444em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`g`}</span></span></span></span>{` are to place vote `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.43056em",
                    "verticalAlign": "0em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`v`}</span></span></span></span>{` on said comment than those outside group `}<span parentName="p" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.19444em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`g`}</span></span></span></span>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Here's how we compute `}<a parentName="p" {...{
            "href": "/Representativeness",
            "title": "Representativeness"
          }}>{`Representativeness`}</a>{` :`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`We first estimate the `}<strong parentName="p">{`probably`}</strong>{` that a person in a group `}<span parentName="p" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.625em",
                        "verticalAlign": "-0.19444em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03588em"
                      }
                    }}>{`g`}</span></span></span></span>{` votes `}<span parentName="p" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.43056em",
                        "verticalAlign": "0em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03588em"
                      }
                    }}>{`v`}</span></span></span></span>{` on comment `}<span parentName="p" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.43056em",
                        "verticalAlign": "0em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`c`}</span></span></span></span>{` as follows: `}<span parentName="p" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.13889em"
                        }
                      }}>{`P`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t vlist-t2"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.151392em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-2.5500000000000003em",
                                  "marginLeft": "-0.13889em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight",
                                    "style": {
                                      "marginRight": "0.03588em"
                                    }
                                  }}>{`v`}</span></span></span></span><span parentName="span" {...{
                              "className": "vlist-s"
                            }}>{`​`}</span></span><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.15em"
                              }
                            }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                      "className": "mopen"
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03588em"
                      }
                    }}>{`g`}</span><span parentName="span" {...{
                      "className": "mpunct"
                    }}>{`,`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.16666666666666666em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`c`}</span><span parentName="span" {...{
                      "className": "mclose"
                    }}>{`)`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2777777777777778em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mrel"
                    }}>{`=`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2777777777777778em"
                      }
                    }}></span></span><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1.53em",
                        "verticalAlign": "-0.52em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mopen nulldelimiter"
                      }}></span><span parentName="span" {...{
                        "className": "mfrac"
                      }}><span parentName="span" {...{
                          "className": "vlist-t vlist-t2"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "1.01em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-2.655em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}>{`2`}</span><span parentName="span" {...{
                                      "className": "mbin mtight"
                                    }}>{`+`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight",
                                      "style": {
                                        "marginRight": "0.10903em"
                                      }
                                    }}>{`N`}</span><span parentName="span" {...{
                                      "className": "mopen mtight"
                                    }}>{`(`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight",
                                      "style": {
                                        "marginRight": "0.03588em"
                                      }
                                    }}>{`g`}</span><span parentName="span" {...{
                                      "className": "mpunct mtight"
                                    }}>{`,`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`c`}</span><span parentName="span" {...{
                                      "className": "mclose mtight"
                                    }}>{`)`}</span></span></span></span><span parentName="span" {...{
                                "style": {
                                  "top": "-3.23em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "frac-line",
                                  "style": {
                                    "borderBottomWidth": "0.04em"
                                  }
                                }}></span></span><span parentName="span" {...{
                                "style": {
                                  "top": "-3.485em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}>{`1`}</span><span parentName="span" {...{
                                      "className": "mbin mtight"
                                    }}>{`+`}</span><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mathnormal mtight",
                                        "style": {
                                          "marginRight": "0.10903em"
                                        }
                                      }}>{`N`}</span><span parentName="span" {...{
                                        "className": "msupsub"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-t vlist-t2"
                                        }}><span parentName="span" {...{
                                            "className": "vlist-r"
                                          }}><span parentName="span" {...{
                                              "className": "vlist",
                                              "style": {
                                                "height": "0.16454285714285719em"
                                              }
                                            }}><span parentName="span" {...{
                                                "style": {
                                                  "top": "-2.357em",
                                                  "marginLeft": "-0.10903em",
                                                  "marginRight": "0.07142857142857144em"
                                                }
                                              }}><span parentName="span" {...{
                                                  "className": "pstrut",
                                                  "style": {
                                                    "height": "2.5em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "sizing reset-size3 size1 mtight"
                                                }}><span parentName="span" {...{
                                                    "className": "mord mathnormal mtight",
                                                    "style": {
                                                      "marginRight": "0.03588em"
                                                    }
                                                  }}>{`v`}</span></span></span></span><span parentName="span" {...{
                                              "className": "vlist-s"
                                            }}>{`​`}</span></span><span parentName="span" {...{
                                            "className": "vlist-r"
                                          }}><span parentName="span" {...{
                                              "className": "vlist",
                                              "style": {
                                                "height": "0.143em"
                                              }
                                            }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                      "className": "mopen mtight"
                                    }}>{`(`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight",
                                      "style": {
                                        "marginRight": "0.03588em"
                                      }
                                    }}>{`g`}</span><span parentName="span" {...{
                                      "className": "mpunct mtight"
                                    }}>{`,`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`c`}</span><span parentName="span" {...{
                                      "className": "mclose mtight"
                                    }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                              "className": "vlist-s"
                            }}>{`​`}</span></span><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.52em"
                              }
                            }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                        "className": "mclose nulldelimiter"
                      }}></span></span></span></span></span>{`. Here:`}</p>
            <ul parentName="li">
              <li parentName="ul"><span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "1em",
                          "verticalAlign": "-0.25em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord"
                      }}><span parentName="span" {...{
                          "className": "mord mathnormal",
                          "style": {
                            "marginRight": "0.10903em"
                          }
                        }}>{`N`}</span><span parentName="span" {...{
                          "className": "msupsub"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.151392em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-2.5500000000000003em",
                                    "marginLeft": "-0.10903em",
                                    "marginRight": "0.05em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "2.7em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "sizing reset-size6 size3 mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`a`}</span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.15em"
                                }
                              }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "mopen"
                      }}>{`(`}</span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.03588em"
                        }
                      }}>{`g`}</span><span parentName="span" {...{
                        "className": "mpunct"
                      }}>{`,`}</span><span parentName="span" {...{
                        "className": "mspace",
                        "style": {
                          "marginRight": "0.16666666666666666em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal"
                      }}>{`c`}</span><span parentName="span" {...{
                        "className": "mclose"
                      }}>{`)`}</span></span></span></span>{` is the number of people in group `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.625em",
                          "verticalAlign": "-0.19444em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.03588em"
                        }
                      }}>{`g`}</span></span></span></span>{` who vote `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.43056em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.03588em"
                        }
                      }}>{`v`}</span></span></span></span>{` (`}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.68333em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal"
                      }}>{`A`}</span></span></span></span>{`gree or `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.68333em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.02778em"
                        }
                      }}>{`D`}</span></span></span></span>{`isagree or `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.68333em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.13889em"
                        }
                      }}>{`P`}</span></span></span></span>{`ass) on comment `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.43056em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal"
                      }}>{`c`}</span></span></span></span></li>
              <li parentName="ul"><span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "1em",
                          "verticalAlign": "-0.25em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.10903em"
                        }
                      }}>{`N`}</span><span parentName="span" {...{
                        "className": "mopen"
                      }}>{`(`}</span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.03588em"
                        }
                      }}>{`g`}</span><span parentName="span" {...{
                        "className": "mpunct"
                      }}>{`,`}</span><span parentName="span" {...{
                        "className": "mspace",
                        "style": {
                          "marginRight": "0.16666666666666666em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal"
                      }}>{`c`}</span><span parentName="span" {...{
                        "className": "mclose"
                      }}>{`)`}</span></span></span></span>{` is the number of people in group `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.625em",
                          "verticalAlign": "-0.19444em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.03588em"
                        }
                      }}>{`g`}</span></span></span></span>{` who vote at `}<strong parentName="li">{`all`}</strong>{` on comment `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.43056em",
                          "verticalAlign": "0em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal"
                      }}>{`c`}</span></span></span></span>{`.`}</li>
              <li parentName="ul">{`The values of 1 & 2 added to the numerator and denominator of the above fraction are `}<strong parentName="li">{`psuedocounts`}</strong>{`, and are used as a semi-naive prior in frequentist statistics.`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`We then compute `}<span parentName="p" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.00773em"
                        }
                      }}>{`R`}</span><span parentName="span" {...{
                        "className": "msupsub"
                      }}><span parentName="span" {...{
                          "className": "vlist-t vlist-t2"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.151392em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-2.5500000000000003em",
                                  "marginLeft": "-0.00773em",
                                  "marginRight": "0.05em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "2.7em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight",
                                    "style": {
                                      "marginRight": "0.03588em"
                                    }
                                  }}>{`v`}</span></span></span></span><span parentName="span" {...{
                              "className": "vlist-s"
                            }}>{`​`}</span></span><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.15em"
                              }
                            }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                      "className": "mopen"
                    }}>{`(`}</span><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03588em"
                      }
                    }}>{`g`}</span><span parentName="span" {...{
                      "className": "mpunct"
                    }}>{`,`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.16666666666666666em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`c`}</span><span parentName="span" {...{
                      "className": "mclose"
                    }}>{`)`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2777777777777778em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mrel"
                    }}>{`=`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2777777777777778em"
                      }
                    }}></span></span><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1.53em",
                        "verticalAlign": "-0.52em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mopen nulldelimiter"
                      }}></span><span parentName="span" {...{
                        "className": "mfrac"
                      }}><span parentName="span" {...{
                          "className": "vlist-t vlist-t2"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "1.01em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-2.655em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mathnormal mtight",
                                        "style": {
                                          "marginRight": "0.13889em"
                                        }
                                      }}>{`P`}</span><span parentName="span" {...{
                                        "className": "msupsub"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-t vlist-t2"
                                        }}><span parentName="span" {...{
                                            "className": "vlist-r"
                                          }}><span parentName="span" {...{
                                              "className": "vlist",
                                              "style": {
                                                "height": "0.16454285714285719em"
                                              }
                                            }}><span parentName="span" {...{
                                                "style": {
                                                  "top": "-2.357em",
                                                  "marginLeft": "-0.13889em",
                                                  "marginRight": "0.07142857142857144em"
                                                }
                                              }}><span parentName="span" {...{
                                                  "className": "pstrut",
                                                  "style": {
                                                    "height": "2.5em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "sizing reset-size3 size1 mtight"
                                                }}><span parentName="span" {...{
                                                    "className": "mord mathnormal mtight",
                                                    "style": {
                                                      "marginRight": "0.03588em"
                                                    }
                                                  }}>{`v`}</span></span></span></span><span parentName="span" {...{
                                              "className": "vlist-s"
                                            }}>{`​`}</span></span><span parentName="span" {...{
                                            "className": "vlist-r"
                                          }}><span parentName="span" {...{
                                              "className": "vlist",
                                              "style": {
                                                "height": "0.143em"
                                              }
                                            }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                      "className": "mopen mtight"
                                    }}>{`(`}</span><span parentName="span" {...{
                                      "className": "mord accent mtight"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.56778em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.7em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "2.7em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord mathnormal mtight",
                                                "style": {
                                                  "marginRight": "0.03588em"
                                                }
                                              }}>{`g`}</span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.7em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "2.7em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "accent-body",
                                                "style": {
                                                  "left": "-0.22222em"
                                                }
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`ˉ`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.19444em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "mpunct mtight"
                                    }}>{`,`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`c`}</span><span parentName="span" {...{
                                      "className": "mclose mtight"
                                    }}>{`)`}</span></span></span></span><span parentName="span" {...{
                                "style": {
                                  "top": "-3.23em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "frac-line",
                                  "style": {
                                    "borderBottomWidth": "0.04em"
                                  }
                                }}></span></span><span parentName="span" {...{
                                "style": {
                                  "top": "-3.485em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "sizing reset-size6 size3 mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}><span parentName="span" {...{
                                      "className": "mord mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mathnormal mtight",
                                        "style": {
                                          "marginRight": "0.13889em"
                                        }
                                      }}>{`P`}</span><span parentName="span" {...{
                                        "className": "msupsub"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-t vlist-t2"
                                        }}><span parentName="span" {...{
                                            "className": "vlist-r"
                                          }}><span parentName="span" {...{
                                              "className": "vlist",
                                              "style": {
                                                "height": "0.16454285714285719em"
                                              }
                                            }}><span parentName="span" {...{
                                                "style": {
                                                  "top": "-2.357em",
                                                  "marginLeft": "-0.13889em",
                                                  "marginRight": "0.07142857142857144em"
                                                }
                                              }}><span parentName="span" {...{
                                                  "className": "pstrut",
                                                  "style": {
                                                    "height": "2.5em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "sizing reset-size3 size1 mtight"
                                                }}><span parentName="span" {...{
                                                    "className": "mord mathnormal mtight",
                                                    "style": {
                                                      "marginRight": "0.03588em"
                                                    }
                                                  }}>{`v`}</span></span></span></span><span parentName="span" {...{
                                              "className": "vlist-s"
                                            }}>{`​`}</span></span><span parentName="span" {...{
                                            "className": "vlist-r"
                                          }}><span parentName="span" {...{
                                              "className": "vlist",
                                              "style": {
                                                "height": "0.143em"
                                              }
                                            }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                      "className": "mopen mtight"
                                    }}>{`(`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight",
                                      "style": {
                                        "marginRight": "0.03588em"
                                      }
                                    }}>{`g`}</span><span parentName="span" {...{
                                      "className": "mpunct mtight"
                                    }}>{`,`}</span><span parentName="span" {...{
                                      "className": "mord mathnormal mtight"
                                    }}>{`c`}</span><span parentName="span" {...{
                                      "className": "mclose mtight"
                                    }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                              "className": "vlist-s"
                            }}>{`​`}</span></span><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.52em"
                              }
                            }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                        "className": "mclose nulldelimiter"
                      }}></span></span></span></span></span></p>
            <ul parentName="li">
              <li parentName="ul">{`Here `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.7622199999999999em",
                          "verticalAlign": "-0.19444em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord accent"
                      }}><span parentName="span" {...{
                          "className": "vlist-t vlist-t2"
                        }}><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.56778em"
                              }
                            }}><span parentName="span" {...{
                                "style": {
                                  "top": "-3em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal",
                                  "style": {
                                    "marginRight": "0.03588em"
                                  }
                                }}>{`g`}</span></span><span parentName="span" {...{
                                "style": {
                                  "top": "-3em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "pstrut",
                                  "style": {
                                    "height": "3em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "accent-body",
                                  "style": {
                                    "left": "-0.22222em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "mord"
                                  }}>{`ˉ`}</span></span></span></span><span parentName="span" {...{
                              "className": "vlist-s"
                            }}>{`​`}</span></span><span parentName="span" {...{
                            "className": "vlist-r"
                          }}><span parentName="span" {...{
                              "className": "vlist",
                              "style": {
                                "height": "0.19444em"
                              }
                            }}><span parentName="span"></span></span></span></span></span></span></span></span>{` is the `}<strong parentName="li">{`complement`}</strong>{` of `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.625em",
                          "verticalAlign": "-0.19444em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.03588em"
                        }
                      }}>{`g`}</span></span></span></span>{`, that is, all the participants in the conversation who are `}<strong parentName="li">{`not`}</strong>{` members of group `}<span parentName="li" {...{
                  "className": "katex"
                }}><span parentName="span" {...{
                    "className": "katex-html",
                    "aria-hidden": "true"
                  }}><span parentName="span" {...{
                      "className": "base"
                    }}><span parentName="span" {...{
                        "className": "strut",
                        "style": {
                          "height": "0.625em",
                          "verticalAlign": "-0.19444em"
                        }
                      }}></span><span parentName="span" {...{
                        "className": "mord mathnormal",
                        "style": {
                          "marginRight": "0.03588em"
                        }
                      }}>{`g`}</span></span></span></span>{`.`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Selection criteria are a bit more challenging to describe, but this involves looking at the two-property test (basically the fisher test) and multiplying this by the `}<a parentName="p" {...{
                "href": "/Representativeness",
                "title": "Representativeness"
              }}>{`Representativeness`}</a>{` to obtain a mashup number reflecting both estimated effect size and confidence.`}</p>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      